<template>
  <b-table-simple
    id="professional-procedures-table"
    striped
    responsive
    class="mt-3 mx-auto"
  >
    <tbody>
      <tr class="tr-header">
        <th scope="col" width="25%">Tipo</th>
        <th scope="col" width="40%">Procedimento</th>
        <th scope="col" width="10%">Dias da semana</th>
        <th scope="col" width="15%">Périodos do dia</th>
        <th scope="col" width="10%"></th>
      </tr>

      <tr v-for="(procedure, index) in procedures" :key="index" class="tr-header">
        <td width="25%">{{procedure.type}}</td>
        <td width="40%">{{procedure.item?.name }}</td>
        <td width="10%" class="pl-2">
          <span v-for="(day, index) in getWeekDaysValues(procedure.pub_schedule_intervals)" :key="index">
            {{day}} <br/>
          </span>
        </td>
        <td width="15%">
          <span v-for="(interval, index) in getIntervalsValues(procedure.pub_schedule_intervals)" :key="index">
            {{interval}} <br/>
          </span>
        </td>
        <td width="10%">
          <v-edit
            v-can="'AgOnlProc3'"
            class="icon edit mr-1"
            @click="editProcedure(procedure)"
          />

          <v-delete
            v-can="'AgOnlProc4'"
            @click="deleteProcedure(procedure)"
            class="icon delete"
          />
        </td>
      </tr>

      <tr v-if="Object.keys(procedures).length === 0" class="tr-header">
        <td width="100%" class="text-center" colspan="5">Adicione procedimentos ao atendimento online através do botão acima.</td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import Delete from '@/assets/icons/delete.svg'
import Edit from '@/assets/icons/edit.svg'

export default {
  name: 'professional-procedures-table',
  components: {
    'v-delete': Delete,
    'v-edit': Edit
  },
  props: {
    procedures: Array,
    editProcedure: Function
  },
  
  data() {
    return {
      prof: false,
      weekDays: [],
      intervals: []
    }
  },
  methods: {
    getWeekDaysValues(intervals){
      const result = []
      intervals.forEach(interval => {
        let day = ''
        switch (interval.day){
          case 0:
            day = 'Dom'
            break
          case 1:                          
            day = 'Seg'
            break
          case 2:
            day = 'Ter'
            break                          
          case 3:
            day = 'Qua'
            break
          case 4:
            day = 'Qui'
            break
          case 5:
            day = 'Sex'
            break
          case 6:
            day = 'Sáb'
            break
        }
        result.push(result.includes(day) ? '' : day)
      })

      return result
    },

    getIntervalsValues(intervals){
      let result = []    
      let day
      let pass = false
      let dayArray = []

      intervals.forEach(interval => {
        if(day !== interval.day) {
          if(dayArray.length){
            dayArray.sort((a, b) => { return parseInt(a.slice(0,5).replace(':','')) - parseInt(b.slice(0,5).replace(':',''))})
            result = result.concat(dayArray)
            dayArray = []
          }
          result.push(interval.day_interval.start_time.slice(0,5)+ ' - ' + interval.day_interval.end_time.slice(0,5))
          pass = true
        }
        else {
          if(pass){
            dayArray.push(result.pop())
            pass = false
          }
          dayArray.push(interval.day_interval.start_time.slice(0,5)+ ' - ' + interval.day_interval.end_time.slice(0,5))
        }

        day = interval.day
      })
      
      if(dayArray.length){
        dayArray.sort((a, b) => { return parseInt(a.slice(0,5).replace(':','')) - parseInt(b.slice(0,5).replace(':',''))})
        result = result.concat(dayArray)
        dayArray = []
      }

      return result
    },
    
    async deleteProcedure(procedure) {
      if (procedure.id) {
        const isLoading = this.$loading.show()
        try {
          await this.api.deletePublicScheduleProfessionalProcedures(procedure.id)
          this.$toast.success('Procedimento do profissional removido com sucesso!')
          this.$emit('reload-procedures')
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      }
    },
  }
}
</script>

<style lang="scss">
#professional-procedures-table {
  .table th, .table td {
    vertical-align: middle;
    border-top: 1px dashed var(--neutral-200);
    font-size: 13px;
  }

  .icon {
    width: 20px;
    height: 24px;
    cursor: pointer;

    &.edit {
      stroke: var(--blue-500);
    }

    &.delete {
      stroke: var(--red-500);
    }
  }

  .tr-header {
    border: none;
    th:first-child {
      padding-left: 10px;
    }

    .table th, .table td {
      padding: 0;
      vertical-align: middle;
      border-top: none;
    }

    td:first-child {
      padding: 16px 2px 16px 2px !important;
      max-width: 25ch;
    }
  }

  th {
    font-size: 14px;
    color: var(--type-active);
  }

  .td {
    padding: 0.75rem !important;
  }
}
</style>
